import { createTheme, ThemeOptions } from "@mui/material/styles";

export const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: "#7643EB",
      light: "rgb(244,52,127, 0.1)",
      dark: "rgb(244,52,127, 0.8)",
    },
    secondary: {
      main: "#6A1B9A",
      light: "rgb(247, 50, 30, 0.1)",
      dark: "rgb(247, 50, 30, 1)",
    },
    warning: {
      main: "#FF4F3D",
      light: "rgb(247, 50, 30, 0.1)",
      dark: "rgb(247, 50, 30, 1)",
    },
    info: {
      main: "#F2F2F2",
      light: "rgb(242, 242, 242, 0.1)",
      dark: "rgb(242, 242, 242, 1)",
    },
    background: {
      default: "#ffffff",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
  },
  typography: {
    fontFamily: "mundial",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#6A1B9A",
            color: "#FFFFFF",
          },
          padding: "6px 10px",
          textTransform: "capitalize",
          fontFamily: "mundial",
          fontSize: "14px",
          borderRadius: "5px",
          height: "35px",
          width: "auto",
        },
        outlined: {
          "&:hover": {
            borderColor: "#7643EB",
            backgroundColor: "#7643EB",
            color: "white",
            border: "1.5px solid #7643EB",
          },
          textTransform: "capitalize",
          fontFamily: "mundial",
          fontSize: "14px",
          borderRadius: "5px",
          height: "35px",
          padding: "6px 10px",
          width: "auto",
          border: "1.5px solid #7643EB",
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "mundial",
          ["& input::placeholder"]: {
            fontSize: "12px",
            fontFamily: "mundial",
          },
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          marginTop: "1.4vh",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          fontFamily: "mundial",
          color: "#2B2D22",
          fontSize: "17px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "mundial",
          color: "#2B2D22",
          opacity: "70%",
          fontSize: "12px",
        },
        shrink: ({ ownerState }) => ({
          ...(ownerState.shrink && {
            fontSize: "16px ",
            lineHeight: "4vh",
            marginLeft: "0vw",
          }),
        }),
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontFamily: "Mundial",
          fontSize: "20px",
          lineHeight: "27px",
          color: "#111111",
        },
        notchedOutline: {
          borderColor: "#ccc",
        },
        root: {
          background: "white",
          "&.Mui-focused": {
            background: "#F8F8F9",
            boxShadow: "0px 3px 6px #7643EB80",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "mundial",
          color: "#2B2D22",
          fontSize: "14px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
        },
        elevation1: {
          boxShadow: "0px 2px 10px #0000001A",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 5px 20px #0000001A",
          borderRadius: "16px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 5px 20px #0000001A",
          borderRadius: "8px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "mundial",
          color: "#2B2D22",
          fontSize: "14px",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: "mundial",
          fontSize: "14px",
          color: "#2B2D22",
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: "mundial",
        },
        option: {
          fontFamily: "mundial",
          fontSize: "16px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#2B2D22",
          fontFamily: "mundial",
        },
        h1: {
          fontFamily: "mundial",
          fontSize: "24px",
          // fontWeight: "500",
          "@media (min-width:600px)": {
            fontSize: "24px",
          },
          "@media (min-width:900px)": {
            fontSize: "24px",
          },
          "@media (min-width:1200px)": {
            fontSize: "24px",
          },
          "@media (min-width:1536px)": {
            fontSize: "26px",
          },
        },
        h2: {
          fontFamily: "mundial",
          fontSize: "20px",
          "@media (min-width:600px)": {
            fontSize: "20px",
          },
          "@media (min-width:900px)": {
            fontSize: "20px",
          },
          "@media (min-width:1200px)": {
            fontSize: "20px",
          },
          "@media (min-width:1536px)": {
            fontSize: "22px",
          },
        },
        h3: {
          fontFamily: "mundial",
          fontSize: "24px",
        },
        h4: {
          fontFamily: "mundial",
          fontSize: "20px",
        },
        h5: {
          fontFamily: "mundial",
          fontSize: "18px",
        },
        h6: {
          fontFamily: "mundial",
          fontSize: "16px",
        },
        subtitle1: {
          fontFamily: "mundial",
          fontSize: "16px",
          fontWeight: "300",
          opacity: "85%",
          "@media (min-width:600px)": {
            fontSize: "16px",
          },
          "@media (min-width:900px)": {
            fontSize: "16px",
          },
          "@media (min-width:1200px)": {
            fontSize: "16px",
          },
          "@media (min-width:1536px)": {
            fontSize: "18px",
          },
        },
        subtitle2: {
          fontFamily: "mundial",
          fontSize: "14px",
          "@media (min-width:600px)": {
            fontSize: "14px",
          },
          "@media (min-width:900px)": {
            fontSize: "14px",
          },
          "@media (min-width:1200px)": {
            fontSize: "14px",
          },
          "@media (min-width:1536px)": {
            fontSize: "16px",
          },
        },
        body1: {
          fontFamily: "mundial",
          fontSize: "12px",
          "@media (min-width:600px)": {
            fontSize: "12px",
          },
          "@media (min-width:900px)": {
            fontSize: "12px",
          },
          "@media (min-width:1200px)": {
            fontSize: "12px",
          },
          "@media (min-width:1536px)": {
            fontSize: "14px",
          },
        },
        body2: {
          fontFamily: "mundial",
          opacity: "85%",
          fontSize: "10px",
        },
        caption: {
          fontFamily: "mundial",
          fontSize: "12px",
          opacity: "85%",
        },
      },
    },
  },
});
