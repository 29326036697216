import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  marginRight: "1.8vw",
  border: "1px solid black",
  "& .MuiCircularProgress-root": {
    color: "#7643EB",
  },
  "&.MuiButton-outlined": {
    border: "2px solid #7643EB",
  },
}));

export const LoaderButton = ({
  submitLoader,
  onClick,
  isDisabled,
  title,
  width,
  height,
  isSubmit = true,
}: any) => {
  return submitLoader ? (
    <StyledLoadingButton
      loading
      loadingPosition="center"
      startIcon={<SaveIcon />}
      variant="outlined"
      sx={{ width: width, height: height }}
    />
  ) : (
    <Button
      disabled={isDisabled}
      type={isSubmit ? "submit" : "button"}
      onClick={onClick}
      variant="contained"
      sx={{ marginRight: 4, width: width, height: height }}
    >
      <Typography color={"white"} variant="subtitle2">
        {title}
      </Typography>
    </Button>
  );
};
